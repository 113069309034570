<template>
  <div class="intro-screen">
    <img
      class="intro-screen__bg"
      src="@/assets/img/splash-screen.jpg"/>
    <div class="intro-screen__content">
      <p>Все соделал Он прекрасным<br> в свое время...</p>
      <span class="subtitle">Екклесиаст 3:11</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntroScreen',

  mounted () {
    document.body.style.overflow = 'hidden'
    setTimeout(() => {
      this.$emit('intro')
      document.body.style.overflow = 'auto'
    }, 7500)
  }
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .intro-screen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 100;
    opacity: 1;
    animation: fadeout 3s forwards;
    animation-delay: 4.5s;

    &__bg {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__content {
      color: $color-white;
      position: fixed;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      opacity: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 2.4rem;
      line-height: 4rem;
      animation: fadein 5s forwards;
      animation-delay: .5s;

      .subtitle {
        font-size: 1.6rem;
        margin-top: 4rem;
      }
    }
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeout {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
</style>
