<template lang="pug">
  div
    header.app-header(:class="classes")
      icon-logo
      button.btn.btn--ghost.btn--icon(
        @click="toggleMenu")
        .app-header__menu
          icon-menu
</template>

<script>
import IconLogo from '@/components/svg/IconLogo'
import IconMenu from '@/components/svg/IconMenu'

export default {
  name: 'AppHeader',

  components: {
    IconLogo,
    IconMenu
  },

  computed: {
    isMenuOpened () {
      return this.$store.getters.isMenuOpened
    },

    classes () {
      return {
        'app-header--dark': this.isMenuOpened ||
          this.$route.path.includes('dress')
      }
    }
  },

  methods: {
    toggleMenu () {
      this.$store.dispatch('toggleMenu')
    }
  }
}
</script>

<style lang="scss">
  @import '../../sass/abstracts/_variables.scss';

  .app-header {
    width: 100%;
    position: absolute;
    padding: 0 3rem;
    top: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 15;
    color: $color-white;
    transition: .5s;

    &__logo {
      width: 7.2rem;
    }

    &__menu {
      color: $color-white;
      width: 3rem;
    }

    &--dark {
      color: $color-primary;
    }

    &--dark &__menu {
      color: $color-primary;
    }
  }
</style>
